.App {
  font-family: 'Varela Round', sans-serif;
}

.ml-03 {
  margin-left: 0.3em;
}

.mb-03 {
  margin-bottom: 0.3em !important;
}

.pt-52 {
  padding-top: 52px;
}

.shadow-bottom {
  -webkit-box-shadow: 0px -1px 5px 0px rgba(189,189,189,1);
  -moz-box-shadow: 0px -1px 5px 0px rgba(189,189,189,1);
  box-shadow: 0px -1px 5px 0px rgba(189,189,189,1);
}

.gradient-blue {
  background: #1488CC;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #2B32B2, #1488CC);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #2B32B2, #1488CC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient-light-blue {
  background: #56CCF2;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #2F80ED, #56CCF2);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #2F80ED, #56CCF2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
